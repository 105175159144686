<template>
  <v-row justify="center" id="call-for-papers">
    <v-col cols="12" md="8">
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >3rd Workshop on Human-Centric Music Information Research
          (HCMIR25)</v-card-title
        >
        <v-card-title class="text-h5 mb-3"
          >September 20th (Saturday) at KAIST, Daejeon</v-card-title
        >

        <v-card-text class="text-body">
          <span class="highlight-bold-text"
            >More info on the workshop webpage:
          </span>

          <a
            href="https://sites.google.com/view/hcmir25/home"
            target="_blank"
            style="color: #1487c8"
          >
            https://sites.google.com/view/hcmir25/home
          </a>
          <br /><br />
          <span class="highlight-text"
            >A half-day workshop bringing together researchers, practitioners,
            and artists to explore the ethical, societal, and human-centred
            dimensions of MIR</span
          ><br /><br />
          Music and technology have long intertwined, transforming how we
          create, share, and experience music. From composition to listening,
          advancements in Music Information Research (MIR) continue to reshape
          our relationship with music, enabling new forms of creativity and
          accessibility. However, alongside these innovations, critical
          questions arise about their broader implications.<br /><br />
          The 3rd edition of the HCMIR workshop seeks to explore MIR's ethical,
          societal, and human-centred dimensions. How can we ensure MIR systems
          are inclusive, ethical, and aligned with human values? How do people
          perceive and engage with AI-driven music technologies? What role are
          artists playing in shaping these tools? How can design principles
          prioritize accessibility and usability while supporting human-AI
          collaboration in music creation and discovery?<br /><br />
          This workshop invites researchers, practitioners, and artists to
          engage in a multidisciplinary discussion on the societal impact, human
          experience, and future directions of MIR. By addressing these topics,
          we aim to foster a deeper understanding of how MIR systems can empower
          individuals, support creativity, and enhance the human experience of
          music. <br /><br />

          We invite the research community to submit short papers on the
          following relevant topics, but not limited to: <br /><br />
          <ul class="dot-list">
            <li>Ethics and Societal Impact of MIR</li>
            <li>Perception and Evaluation in MIR</li>
            <li>Human-AI Collaboration in MIR</li>
            <li>User Experience (UX) and Interface (UI) Design in MIR</li>
            <li>Interdisciplinary Perspectives on Human-Centric MIR</li>
            <li>Future Directions in Human-Centric MIR</li>
          </ul>
          <br />

          <span class="highlight-text">Important dates</span>

          <br /><br />
          <ul class="dot-list">
            <li>Open submission: June 2nd, 2025</li>
            <li>Paper submission deadline: June 18th, 2025</li>
            <li>Author notification: July 21st, 2025</li>
            <li>Camera-ready version: September 1st, 2025</li>
          </ul>
          <br />
          All deadlines are Anywhere on Earth (UTC -12)
        </v-card-text>

        <v-divider></v-divider>
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >12th International Conference on Digital Libraries for
          Musicology</v-card-title
        >

        <v-card-title class="text-h5 mb-3"
          >September 26th (Friday) at Sogang University, Seoul</v-card-title
        >
        <v-card-text class="text-body">
          <span class="highlight-bold-text"
            >More info on the workshop webpage:
          </span>
          <a
            href="https://dlfm.web.ox.ac.uk/"
            target="_blank"
            style="color: #1487c8"
          >
            https://dlfm.web.ox.ac.uk/
          </a>
          <br /><br />
          <span class="highlight-text"
            >The Digital Libraries for Musicology (DLfM) conference is the
            premiere venue for scholars engaging with digital libraries in the
            domain of music and musicology. It provides a forum for musicians,
            musicologists, librarians, and technologists to share findings and
            expertise.</span
          ><br /><br />
          While Digital Libraries have long offered facilities to provide
          multimedia content, the requirements of systems for library music are
          complex. The many forms taken by musical data, the needs for
          connections between these, and the importance of scholarly and
          historical contextual information all require special care to support
          meaningful engagement with the materials.<br /><br />
          The Digital Libraries for Musicology (DLfM) conference presents a
          venue specifically for those working on, and with, Digital Library
          systems and content in the domain of music and musicology. This
          includes Music Digital Library systems, their application and use in
          musicology, technologies for enhanced access and organisation of
          musics in Digital Libraries, bibliographic and metadata for music,
          intersections with music Linked Data, and the challenges of working
          with the multiple representations of music across large-scale digital
          collections such as the Internet Archive and HathiTrust.<br /><br />
          DLfM focuses on the implications of music for Digital Libraries and
          Digital Libraries research, especially when pushing the boundaries of
          contemporary musicology through the application of techniques from
          more technology-oriented fora such as ISMIR and ICMC. This instalment
          of DLfM conference follows previous conferences in Stellenbosch,
          Milan, Prague, Montreal, The Hague, Paris, New York, Shanghai,
          Knoxville and London. <br /><br />
          DLfM partners with IAML, ISMIR, and other conferences to encourage new
          collaborations and discussions surrounding prominent issues in our
          shared field. <br /><br />

          DLfM welcomes contributions related to any aspect of digital libraries
          and musicology, including topics related to musical archiving and
          retrieval, cataloguing and classification, musical databases, special
          collections, music encodings and representations, computational
          musicology, or music information retrieval (MIR).<br /><br />

          Topics of interest include, but are not limited to: <br /><br />
          <ul class="dot-list">
            <li>Building and managing digital music collections</li>
            <li>Access, interfaces, and ergonomics</li>
            <li>Musicological knowledge</li>
            <li>Improving data for musicology</li>
          </ul>
          <br />

          <span class="highlight-text">Important dates</span>

          <br /><br />
          <ul class="dot-list">
            <li>
              Paper (full paper and short paper) submission deadline: May 9th,
              2025
            </li>
            <li>Notification of paper acceptance: June 27th, 2025</li>
            <li>Poster submission deadline: July 12th, 2025</li>
            <li>
              Space for Music Libraries Challenge submission deadline: July
              12th, 2025
            </li>
            <li>
              Camera-ready submission deadline (full and short papers)*: July
              16th, 2025
            </li>
            <li>Conference registration deadline: September 19th, 2025</li>
            <li>Conference: September 26th, 2025, Seoul, South Korea</li>
          </ul>
          <br />
          All deadlines are at 23:59 AoE.

          <br /><br />
        </v-card-text>

        <br />
        <v-divider></v-divider>
      </v-card>
      <br /><br /><br />
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      expandedPanels: [], // ���� Ȯ��� �г��� �����ϴ� �迭
      sections: [
        {
          items: [
            { content: "Abstract Submission Due", date: "March 21, 2025" },
            { content: "Full Paper Submission Due", date: "March 28, 2025" },
            { content: "Acceptance Notification", date: "June 6, 2025" },
            { content: "Camera-Ready Upload Due", date: "June 28, 2025" },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.dot-list {
  list-style-type: disc; /* �۸Ӹ� ��ȣ ��Ÿ�� ���� */
  font-size: 18px;
  padding-left: 40px; /* �鿩���� �� ���� */
  line-height: 1.8;
}
.text-body {
  font-size: 18px; /* �ؽ�Ʈ ��Ʈ ũ�� ���� */
  line-height: 1.6; /* �������� ���� �� ���� ���� */
  white-space: normal; /* �ٹٲ��� ��� */
  overflow-wrap: break-word; /* �ܾ ���� ���� �ʵ��� �ܾ� ������ �ٹٲ� */
}
.v-card-title {
  white-space: normal; /* ���񿡼� �ٹٲ��� ��� */
  overflow-wrap: break-word; /* �ܾ �߸��� �ʵ��� �ٹٲ� */
}
.styled-row {
  border-bottom: 1px solid #d3d3d3; /* ȸ�� �� */
  padding-bottom: 8px; /* �ٰ� ���� �� ���� */
}
/* Notion ��Ÿ���� �ڵ� ���� */
.code-block {
  background-color: #f5f5f5; /* ���� ȸ�� ��� */
  border: 1px solid #e0e0e0; /* ���� �׵θ� */
  border-radius: 4px; /* �𼭸��� �ձ۰� */
  padding: 12px; /* ���� ���� */
  font-family: "Courier New", Courier, monospace; /* ��뽺���̽� ��Ʈ */
  font-size: 14px; /* ������ ũ���� �ؽ�Ʈ */
  overflow-x: auto; /* �� �ڵ忡 ��ũ�� �߰� */
  white-space: pre-wrap; /* �ڵ� �� �ٲ� */
}
.highlight-text {
  color: #004191;
  font-weight: bold;
}
.highlight-bold-text {
  color: #000000;
  font-weight: bold;
}
</style>
