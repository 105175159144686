<template>
  <v-row justify="center" id="organizing-committee">
    <v-col cols="12" md="8">
      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">General Chairs</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in GeneralChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3"
          >Scientific Program Chairs</v-card-title
        >

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in ProgramChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Tutorial Chairs</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in TutorialChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Publication Chair</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in PublicationChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">LBD Chairs</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in LBDChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Music Chair</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in MusicChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Industry Chairs</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in IndustryChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">DEI Chairs</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in DEIChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Sponsorship Chair</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in SponsorshipChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Virtual Chairs</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in VirtualChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Grant Chairs</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in GrantChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3"
          >Newcomer Initiative Chair</v-card-title
        >

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in NewcomerInitiativeChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-card variant="text" elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3">Web Chair / Designer</v-card-title>

        <v-row justify="start" class="mt-4">
          <v-col
            cols="4"
            md="3"
            class="mb-4"
            v-for="person in WebChairDesignerChairs"
            :key="person.name"
          >
            <v-card variant="text" class="pa-3 text-center" max-width="600">
              <v-img
                :src="person.photo"
                :v-html="person.name"
                max-width="100%"
                height="200px"
                class="mb-2"
              />
              <div class="font-weight-bold">{{ person.name }}</div>
              <div class="text-subtitle-1" v-html="person.affiliation"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script setup>
//import anonymousPhoto from '@/assets/Chairs/anonymous.jpg';
import AmyHung from "@/assets/Chairs/Amy_Hung.jpg";
import HaoWenDong from "@/assets/Chairs/Hao-Wen.jpg";
import HarinLee from "@/assets/Chairs/harin_lee.jpg";
import HyungSeokChoi from "@/assets/Chairs/Hyeong-Seok_Choi.jpg";
import JoonhyungBae from "@/assets/Chairs/Joonhyung Bae.jpg";
import KosetsuTsukuda from "@/assets/Chairs/KosetsuTsukuda.jpg";
import KyungmyunLee from "@/assets/Chairs/KyungMyun.jpg";
import TaegyunKwon from "@/assets/Chairs/TaegyunKwon.jpg";
import AkiraMaezawa from "@/assets/Chairs/maezawa-photo.jpg";
import MagdalenaFuentes from "@/assets/Chairs/magdalena.jpg";
import SeungheonDoh from "@/assets/Chairs/Seungheon_Doh.jpg";
import TomoyasuNakano from "@/assets/Chairs/Tomoyasu.jpg";
import Xiao_Hu from "@/assets/Chairs/Xiao_Hu.jpg";
import YuWang from "@/assets/Chairs/Yu_Wang.jpg";
import SeokJinLee from "@/assets/Chairs/SeokJinLee.jpg";
import WeissChristof from "@/assets/Chairs/WeissChristof.jpg";
import LiSu from "@/assets/Chairs/LiSu.jpg";
import KeunwooChoi from "@/assets/Chairs/KeunwooChoi.jpg";
import DasaemJeong from "@/assets/Chairs/DasaemJeong.jpg";
import JuhanNam from "@/assets/Chairs/JuhanNam.jpg";
import JeongChoi from "@/assets/Chairs/JeongChoi.jpg";
import JaehunKim from "@/assets/Chairs/JaehunKim.jpg";
import MinzWon from "@/assets/Chairs/MinzWon.jpg";
import AliaMorsi from "@/assets/Chairs/AliaMorsi.jpg";

const GeneralChairs = [
  {
    name: "Juhan Nam",
    affiliation: "KAIST",
    photo: JuhanNam,
  },
  {
    name: "Dasaem Jeong",
    affiliation: "Sogang University",
    photo: DasaemJeong,
  },
  {
    name: "Keunwoo Choi",
    affiliation: "Genentech",
    photo: KeunwooChoi,
  },
];

const ProgramChairs = [
  {
    name: "Li Su",
    affiliation: "Academia Sinica",
    photo: LiSu,
  },
  {
    name: "Xiao Hu",
    affiliation: "University of Arizona",
    photo: Xiao_Hu,
  },
  {
    name: "Magdalena Fuentes",
    affiliation: "NYU",
    photo: MagdalenaFuentes,
  },
  {
    name: "Tomoyasu Nakano",
    affiliation: "AIST",
    photo: TomoyasuNakano,
  },
];

const TutorialChairs = [
  {
    name: "HyungSeokChoi",
    affiliation: "ElevenLabs",
    photo: HyungSeokChoi,
  },
  {
    name: "Christof Weiß",
    affiliation: "University of Würzburg",
    photo: WeissChristof,
  },
];

const PublicationChairs = [
  {
    name: "Hao-Wen(Herman) Dong",
    affiliation: "University of Michigan",
    photo: HaoWenDong,
  },
];

const LBDChairs = [
  {
    name: "Kosetsu Tsukuda",
    affiliation: "AIST",
    photo: KosetsuTsukuda,
  },
  {
    name: "Amy Hung",
    affiliation: "Moises AI",
    photo: AmyHung,
  },
];

const MusicChairs = [
  {
    name: "Harin Lee",
    affiliation: "MPI",
    photo: HarinLee,
  },
];

const IndustryChairs = [
  {
    name: "Jaehun Kim",
    affiliation: "Pandora / SiriusXM",
    photo: JaehunKim,
  },
  {
    name: "Akira Maezawa",
    affiliation: "Yamaha",
    photo: AkiraMaezawa,
  },
];

const DEIChairs = [
  {
    name: "Kyung Myun Lee",
    affiliation: "KAIST",
    photo: KyungmyunLee,
  },
  {
    name: "Taegyun Kwon",
    affiliation: "KAIST",
    photo: TaegyunKwon,
  },
];

const SponsorshipChairs = [
  {
    name: "Minz Won",
    affiliation: "Suno",
    photo: MinzWon,
  },
];

const VirtualChairs = [
  {
    name: "Jeong Choi",
    affiliation: "Naver",
    photo: JeongChoi,
  },
  {
    name: "Yu Wang",
    affiliation: "Spotify",
    photo: YuWang,
  },
];

const GrantChairs = [
  {
    name: "Seokjin Lee",
    affiliation: "Kyungpook National University",
    photo: SeokJinLee,
  },
  {
    name: "Alia Morsi",
    affiliation: "UPF",
    photo: AliaMorsi,
  },
];

const NewcomerInitiativeChairs = [
  {
    name: "Seungheon Doh",
    affiliation: "KAIST",
    photo: SeungheonDoh,
  },
];

const WebChairDesignerChairs = [
  {
    name: "Joonhyung Bae",
    affiliation: "KAIST",
    photo: JoonhyungBae,
  },
];
</script>

<style scoped>
.logo-image {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.text-body {
  font-size: 18px;
  line-height: 1.6;
}
</style>
