<template>
  <v-footer
    id="home-footer"
    color="black"
    height="auto"
    :padless="true"
    class="pa-4"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) in social" :key="i">
              <a
                class="text-white pa-1 pa-md-0"
                :href="getLink(s)"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ s }}
              </a>

              <v-divider
                v-if="i < social.length - 1"
                class="mx-4"
                vertical
              />
            </template>
          </div>
        </v-col>

        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          Copyright &copy; ISMIR 2025 Conference Organizing Committee
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script setup>
import { ref } from 'vue'
  const social = ref(['Instagram'])

  const getLink = (platform) => {
    if (platform === 'Instagram') {
      return 'https://www.instagram.com/ismir_conference/' // Instagram ��ũ�� ��ȯ
    } else {
      return '#'
    }
  }
</script>

<style lang="scss">
#home-footer {
  width: 100%;
  height: auto;
  position: relative;
  bottom: 0;
  margin-top: auto;
}

#home-footer a {
  text-decoration: none;
}

body {
  padding-bottom: 0;
}
</style>
