import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';

//import ProgramAtGlancePage from '../views/ProgramAtGlancePage.vue';
//import KeynotePresentationsPage from '../views/ProgramKeynotePresentationsPage.vue';
//import AcceptedPapersPage from '../views/ProgramAcceptedPapersPage.vue';
//import TutorialsPage from '../views/ProgramTutorialsPage.vue';
import SateliteEventsPage from '../views/ProgramSateliteEventsPage.vue';

import CallForPapersPage from '../views/CallForPapersPage.vue';
//import CallForLateBreakingDemoPage from '../views/CallForLateBreakingDemoPage.vue';
import CallForTutorialsPage from '../views/CallForTutorialsPage.vue';
import AuthorGuidelinesPage from '../views/AuthorGuidelinesPage.vue';
//import ImportantDatesPage from '../views/ImportantDatesPage.vue';

//import AttendVenuePage from '../views/AttendVenuePage.vue';
//import AttendTravelPage from '../views/AttendTravelPage.vue';
//import AttendAccommodationPage from '../views/AttendAccommodationPage.vue';

import OrganizingCommitteePage from '../views/OrganizingCommitteePage.vue';

import MentoringPage from '../views/MentoringPage.vue';
//import AccessibilityPage from '../views/AccessibilityPage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home,
  },
  {
    path: '/organizing-committee',
    name: 'OrganizingCommitteePage',
    component: OrganizingCommitteePage,
  },
  
  
  {
    path: '/cfp',
    name: 'cfp',
    component: CallForPapersPage,
  },
  {
    path: '/author-guidelines',
    name: 'author-guidelines',
    component: AuthorGuidelinesPage,
  },
  
  {
    path: '/call-for-tutorials',
    name: 'CallForTutorialsPage',
    component: CallForTutorialsPage,
  },
  {
    path: '/satelite-events',
    name: 'SateliteEventsPage',
    component: SateliteEventsPage,
  },
  {
    path: '/new-to-ismir-mentoring-program-2025',
    name: 'new-to-ismir-mentoring-program-2025',
    component: MentoringPage,
  },
    /*
  {
    path: '/manuscript-accessibility',
    name: 'AccessibilityPage',
    component: AccessibilityPage,
  },
*/
  /*
  {
    path: '/venue-page',
    name: 'VenuePage',
    component: AttendVenuePage,
  },
  {
    path: '/travel-page',
    name: 'TravelPage',
    component: AttendTravelPage,
  },
  {
    path: '/accommodation-page',
    name: 'AccommodationPage',
    component: AttendAccommodationPage,
  },
  {
    path: '/important-dates',
    name: 'ImportantDatesPage',
    component: ImportantDatesPage,
  },
  {
    path: '/call-for-late-breaking-demo',
    name: 'CallForLateBreakingDemoPage',
    component: CallForLateBreakingDemoPage,
  },

  {
    path: '/program-at-glance',
    name: 'ProgramAtGlancePage',
    component: ProgramAtGlancePage,
  },
  {
    path: '/keynote-presentations',
    name: 'KeynotePresentationsPage',
    component: KeynotePresentationsPage,
  },
  {
    path: '/accepted-papers',
    name: 'AcceptedPapersPage',
    component: AcceptedPapersPage,
  },
  {
    path: '/tutorials',
    name: 'TutorialsPage',
    component: TutorialsPage,
  },
  */
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
