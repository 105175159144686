<template>
  <v-row justify="center" id="call-for-papers">
    <v-col cols="12" md="8">
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Overview</v-card-title
        >
        <v-card-text class="text-body">
          The annual conference of the International Society for Music
          Information Retrieval (ISMIR) is the world's leading research forum on
          processing, analyzing, searching, organizing and accessing
          music-related data. The 26th ISMIR conference, to be held in Daejeon,
          Korea and online from September 21-25 2025, welcomes proposals for
          tutorials to be given in conjunction with the conference. Tutorials
          take place at the start of the ISMIR conference, and this year will be
          held on
          <span class="highlight-text">Sunday, September 21</span>
          , 2025. Each tutorial is allocated 3 hours to concentrate on a single
          topic, with tutorials taking place in parallel sessions. Tutorials are
          offered in a hybrid format and are expected to be delivered by at
          least one in-person presenter for quality of experience.
          <br /><br />
          The 26th edition of the ISMIR conference carries a special theme:
          <span class="highlight-text">Harmony of Tradition & Modernity</span>.
          We encourage diverse perspectives on how MIR can bridge past and
          present, and explore the multifaceted intersections of tradition and
          innovation. From the preservation and analysis of traditional music
          forms to the study of contemporary music trends powered by
          computational methods and data, we welcome submissions that address
          the understanding of music as a dynamic and evolving cultural force,
          in addition to technical topics that offer an introduction to
          established and novel processing methods in MIR. To make the
          conference more accessible and inclusive, in addition to the standard
          tutorial suggestions, we welcome tutorial proposals which either cover
          fundamental and introductory topics in MIR, or present an informative
          and stimulating coverage of current MIR topics in a manner that is
          targeted towards people new to the field.
          <br />
        </v-card-text>
        <br />
        <v-divider></v-divider>
        <br />
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Important Dates</v-card-title
        >
        <v-card-text class="text-body">
          Time zone : Anywhere On Earth (AOE)
        </v-card-text>

        <v-container class="table-container">
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Initial Proposal Submission Due</v-col>
            <v-col cols="5" sm="8">Mar 15, 2025</v-col>
          </v-row>
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Revised Proposal Submission Due</v-col>
            <v-col cols="5" sm="8">Mar 31, 2025</v-col>
          </v-row>
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Notification of Acceptance</v-col>
            <v-col cols="5" sm="8">Apr 30, 2025</v-col>
          </v-row>
        </v-container>

        <br />
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Submissions</v-card-title
        >
        <v-card-text class="text-body">
          Initial proposals should be submitted as a PDF attachment via email to

          <span style="color: #1487c8; text-decoration: underline"
            >ismir2025-tutorials@ismir.net</span
          >
          by
          <span class="highlight-text">March 15, 2025</span>.

          <br />
        </v-card-text>
        <br />
        <v-divider></v-divider>
        <br />
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Submission Guidelines</v-card-title
        >
        <v-card-text class="text-body">
          Tutorial proposals should consist of a 2-4 page abstract including the
          following:
        </v-card-text>
        <ul class="dot-list">
          <li>Title</li>
          <li>Presenter names, affiliations, and email addresses</li>
          <li>Motivation and relevance of the tutorial</li>
          <li>
            Outline of the tutorial content, including a short structured Table
            of Contents
          </li>
          <li>Intended and expected audience</li>
          <li>Short biography of the presenters</li>
          <li>Presentation tools and format to be used</li>
          <li>If applicable, relevance to the theme of the conference</li>
          <li>Any other special requirements</li>
        </ul>
        <br />
        <v-divider></v-divider>
      </v-card>
      <br />
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Review Process</v-card-title
        >
        <v-card-text class="text-body">
          Acceptance/rejection decisions will be made by the ISMIR 2025 Tutorial
          Chairs, and notifications will be made by April 30, 2025. The decision
          will depend on the following criteria:
        </v-card-text>
        <ul class="dot-list">
          <li>
            Relevance (i.e., of interest to the whole community or only a
            segment thereof)
          </li>
          <li>
            Suitability to a general academic audience (rather than specialists
            in a particular subfield)
          </li>
          <li>Quality, comprehensiveness and coverage</li>
          <li>
            Originality of topics with respect to previous ISMIR tutorials
          </li>
          <li>Novelty of authors with respect to previous ISMIR tutorials</li>
          <li>Expertise of authors</li>
          <li>Suitability for remote attendees</li>
          <li>
            If relevant, usage of open-source and free software tools/data,
            reproducible research You may refer to the tutorials offered in
            previous years for recently covered topics:
          </li>
        </ul>
        <v-card-text class="text-body">
          You may refer to the tutorials offered in previous years for recently
          covered topics:
        </v-card-text>
        <ul class="dot-list">
          <li>
            <a
              href="https://ismir2024.ismir.net/tutorials"
              target="_blank"
              style="color: #1487c8"
              >ISMIR 2024</a
            >
          </li>
          <li>
            <a
              href="https://ismir2023.ismir.net/tutorials/"
              target="_blank"
              style="color: #1487c8"
              >ISMIR 2023</a
            >
          </li>
          <li>
            <a
              href="https://ismir2022.ismir.net/program/tutorials/"
              target="_blank"
              style="color: #1487c8"
              >ISMIR 2022</a
            >
          </li>
          <li>
            <a
              href="https://ismir2021.ismir.net/tutorials/"
              target="_blank"
              style="color: #1487c8"
              >ISMIR 2021</a
            >
          </li>
        </ul>
        <br />
        <v-divider></v-divider>
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-text class="text-body">
          For additional information, you can reach the tutorial program chairs
          via
          <span style="color: #1487c8; text-decoration: underline"
            >ismir2025-tutorials@ismir.net</span
          >
        </v-card-text>
        <v-card-title class="text-h6 mb-3">
          Hyeong-Seok Choi and Christof Weiß
          <br />
          Tutorial Program Chairs of ISMIR 2025
        </v-card-title>
      </v-card>
      <br /><br /><br />
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.dot-list {
  list-style-type: disc; /* 글머리 기호 스타일 설정 */
  font-size: 18px;
  padding-left: 40px; /* 들여쓰기 값 조정 */
  line-height: 1.8;
}
.text-body {
  font-size: 18px; /* 텍스트 폰트 크기 조정 */
  line-height: 1.6; /* 가독성을 위한 줄 간격 조정 */
  white-space: normal; /* 줄바꿈을 허용 */
  overflow-wrap: break-word; /* 단어가 줄을 넘지 않도록 단어 단위로 줄바꿈 */
}
.v-card-title {
  white-space: normal; /* 제목에서 줄바꿈을 허용 */
  overflow-wrap: break-word; /* 단어가 잘리지 않도록 줄바꿈 */
}
.styled-row {
  border-bottom: 1px solid #d3d3d3; /* 회색 줄 */
  padding-bottom: 8px; /* 줄과 내용 간 여백 */
}
/* Notion 스타일의 코드 블록 */
.code-block {
  background-color: #f5f5f5; /* 연한 회색 배경 */
  border: 1px solid #e0e0e0; /* 연한 테두리 */
  border-radius: 4px; /* 모서리를 둥글게 */
  padding: 12px; /* 내부 여백 */
  font-family: "Courier New", Courier, monospace; /* 모노스페이스 폰트 */
  font-size: 14px; /* 적당한 크기의 텍스트 */
  overflow-x: auto; /* 긴 코드에 스크롤 추가 */
  white-space: pre-wrap; /* 코드 줄 바꿈 */
}
.highlight-text {
  color: #004191;
  font-weight: bold;
}
</style>
