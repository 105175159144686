<template>
  <v-app>
    <!-- AppbarPage ������Ʈ ��� -->
    <AppbarPage />
    <v-main>
      <router-view />
    </v-main>
    <!-- Footer ������Ʈ �߰� -->
    <FooterPage />
  </v-app>
</template>

<script>
import FooterPage from '@/components/FooterPage.vue'; // Footer ������Ʈ ��ο� �°� �����ϼ���
import AppbarPage from '@/components/AppbarPage.vue'; // AppbarPage ������Ʈ ��ο� �°� �����ϼ���

export default {
  name: 'App',

  components: {
    FooterPage,
    AppbarPage, // AppbarPage�� ������Ʈ�� ���
  },

  data() {
    return {
      darkTheme: false,
    };
  },

  methods: {
    toggleTheme() {
      this.darkTheme = !this.darkTheme;
    },
  },
};
</script>

<style scoped>
/* ���� ��Ÿ�� �߰� ���� */
</style>
